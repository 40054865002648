import { COOKIEBAR_MFE_URL } from 'gatsby-env-variables';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

const CookieBar = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? (
    <>
      <Helmet>
        <link rel="stylesheet" href={`${COOKIEBAR_MFE_URL}/styles.css`} />
        <script src={`${COOKIEBAR_MFE_URL}/runtime.js`}></script>
        <script src={`${COOKIEBAR_MFE_URL}/main.js`}></script>
      </Helmet>
      <itau-cookie-consent-banner segment="varejo"></itau-cookie-consent-banner>
    </>
  ) : null;
};

export default CookieBar;
